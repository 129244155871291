import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import routes from "../routes";
import NavItem from "./NavItem";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import logo from "../images/logo.png";
import jwt_decode from "jwt-decode";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { Roles } from "../constants";
import { useNavigate } from "react-router-dom";
import { logout } from "../slices/auth/login";
import CloseIcon from "@mui/icons-material/Close";
import { getProfileHandler } from "../slices/profile/getProfile";

interface IProps {
  routeName: string;
}
const NavBar = (props: IProps) => {
  const { data } = useAppSelector((state) => state.login);
  const profile = useAppSelector((state) => state.getProfile);
  const getRole = () => {
    if (!data?.token) {
      return null;
    }
    const { role } = jwt_decode(data.token) as { role: string };
    if (role === "ADMIN") return Roles.ADMIN;
    if (role === "USER") return Roles.USER;
    return null;
  };

  const role = getRole();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    dispatch(getProfileHandler());
  }, []);
  return (
    <>
      <div className="bg-gray-800 text-white print:hidden">
        <Dialog open={openDialog} fullScreen>
          <DialogTitle>
            <div className="flex justify-end">
              <IconButton onClick={() => setOpenDialog(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col items-end space-y-2">
              {routes.map((route) => {
                const isActive = route.name === props.routeName;

                if (
                  route.roles.length === 0 ||
                  (role && route.roles.includes(role))
                ) {
                  return (
                    <div
                      className={`${isActive && "bg-red-100 "} px-2 py-1`}
                      onClick={() => {
                        navigate(route.path);
                        setOpenDialog(false);
                      }}
                    >
                      {route.name}
                    </div>
                  );
                }
              })}
              <div>
                {role ? (
                  <div
                    onClick={() => dispatch(logout())}
                    className="flex flex-col justify-center px-2 hover:cursor-pointer"
                  >
                    Logout
                  </div>
                ) : (
                  <div
                    onClick={() => navigate("/login")}
                    className="flex flex-col justify-center hover:cursor-pointer bg-amber-500 px-6 py-2 rounded-md"
                  >
                    Login
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <Container>
          <div className="flex justify-between items-center md:items-stretch ">
            <div className="flex  items-center py-2">
              <img src={logo} className="h-16 w-16 mx-2" />
              <p className="flex gap-x-2">
                EFF | <span>{profile.data?.assemblyNumber + "th"}</span>
                <span>{profile.data?.assemblyName}</span>
              </p>
            </div>
            <div className="md:hidden">
              <IconButton className="" onClick={() => setOpenDialog(true)}>
                <MenuIcon className="text-white" />
              </IconButton>
            </div>
            <div className="hidden md:flex space-x-5 ">
              {routes.map((route) => {
                const isActive = route.name === props.routeName;
                if (
                  route.roles.length === 0 ||
                  (role && route.roles.includes(role))
                ) {
                  return (
                    <NavItem
                      isActive={isActive}
                      name={route.name}
                      path={route.path}
                    />
                  );
                }
              })}
              <div className="flex items-center mx-5">
                {role ? (
                  <div
                    onClick={() => dispatch(logout())}
                    className="flex flex-col justify-center px-2 hover:cursor-pointer"
                  >
                    Logout
                  </div>
                ) : (
                  <div
                    onClick={() => navigate("/login")}
                    className="flex flex-col justify-center hover:cursor-pointer bg-amber-500 px-6 py-2 rounded-md"
                  >
                    Login
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default NavBar;
