import { Divider, Table, TableBody, TableCell, TableRow } from '@mui/material'
import React from 'react'
import { baseURL, imgURL } from '../../helper'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { changeStatusHandler } from '../../slices/auth/change-status'
import { IUser } from '../../slices/users/type'

interface IProps {
    user: IUser
}

export const statusButton = (status: string) => {
    if (status === "APPROVED") {
        return <div className='bg-green-600 text-white px-2 py-1 rounded-md'>Approved</div>
    }

    if (status === "REJECTED") {
        return <div className='bg-red-600 text-white px-2 py-1 rounded-md'>Rejected</div>
    }

    if (status === "VERIFIED") {
        return <div className='bg-amber-600 text-white px-2 py-1 rounded-md'>Email Verified</div>
    }
    return <div className='bg-gray-600 text-white px-2 py-1 rounded-md'>Email Unverified</div>
}

const ApprovalItem = (props: IProps) => {
    const dispatch = useAppDispatch()
    const { user } = props;
    const fullName = `${user.firstName} ${user.middleName} ${user.lastName}`



    const rejectHandler = () => {
        dispatch(changeStatusHandler({ code: user.registrationCode, id: user.id, payload: { status: "REJECTED" } }))
    }
    const approveHandler = () => {
        dispatch(changeStatusHandler({ code: user.registrationCode, id: user.id, payload: { status: "APPROVED" } }))

    }
    return (
        <div className='p-2 border rounded-md'>
            <img src={`${imgURL}/${user.image}`} className='w-full rounded-md h-80 object-cover' />
            <div className='m-2 space-y-2'>
                <div className='flex justify-between items-center'>
                    <div className='font-bold capitalize'>{fullName}</div>
                    {statusButton(user.status)}
                </div>
                <div className='my-5'>
                    <Divider />
                </div>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Registration ID</TableCell>
                            <TableCell>{user.registrationCode}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Birth Date</TableCell>
                            <TableCell>{user.birthDate}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Organization Name</TableCell>
                            <TableCell className='capitalize'>{user.company.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>{user.email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>City</TableCell>
                            <TableCell className='capitalize'>{user.city || "--"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Sub-City</TableCell>
                            <TableCell className='capitalize'>{user.subCity || "--"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Position</TableCell>
                            <TableCell className='capitalize'>{user.position}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>{user.phoneNumber || "--"}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                {user.status !== "UNVERIFIED" && <div className='flex space-x-2 '>
                    <button onClick={rejectHandler} className='grow bg-red-100 text-red-600 border-red-600 border px-2 py-1 rounded-md'>Reject</button>
                    <button onClick={approveHandler} className='grow bg-green-100 text-green-600 border-green-600 border px-2 py-1 rounded-md'>Approve</button>
                </div>}
            </div>
        </div>
    )
}

export default ApprovalItem