import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAxiosInstance } from "../../helper";
import { IGenericState } from "../common.type";
import { IVerifyEmailRequestDto } from "./type";

export const verifyEmailHandler = createAsyncThunk<
  void,
  IVerifyEmailRequestDto
>("verify-email", async (data) => {
  try {
    await getAxiosInstance().post(`/auth/verify-email`, data);
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.data) {
      let { message } = e.response.data as { message: string };
      throw new Error(message);
    } else {
      throw e;
    }
  }
});

const initialState: IGenericState = {
  isLoading: false,
  isSuccessful: false,
};
const verifyEmailSlice = createSlice({
  name: "verify-email",
  initialState,
  reducers: {
    resetVerifyEmail(state) {
      state.error = undefined;
      state.isLoading = false;
      state.isSuccessful = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(verifyEmailHandler.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(verifyEmailHandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;
      state.isSuccessful = true;
    });
    builder.addCase(verifyEmailHandler.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetVerifyEmail } = verifyEmailSlice.actions;
export default verifyEmailSlice.reducer;
