import {
    Alert,
    Avatar,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    Link,
    TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { ICompany } from "../slices/companies/type";
import noImage from "../images/no-image.jpg";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import NavBar from "../components/NavBar";
import CancelButton from "../components/CancelButton";
import SubmitButton from "../components/SubmitButton";
import CustomBreadCrumb from "../components/CustomBreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFormik } from "formik"
import * as Yup from 'yup';
import { addCompanyHandler, resetAddCompany } from "../slices/companies/add-company";
import { fetchSingleCompanyHandler } from "../slices/companies/fetch-single-company";
import { baseURL, imgURL } from "../helper";
import { resetUpdateCompany, updateCompanyHandler } from "../slices/companies/update-company";

interface IProps {
    edit: boolean;
}

interface IFormikValues {
    name: string;
    email?: string;
    logo?: File;
    phoneNumber: string;
    address: string;
}

const phoneRegExp = /^\+[1-9]{3}[0-9]{9,10}$/

const CompanyFormSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    phoneNumber: Yup.string().matches(phoneRegExp, { message: "invalid phone number" })
        .required('Required'),
    email: Yup.string().email('Invalid email')
});

const AddEditCompanyPage = (props: IProps) => {
    const navigate = useNavigate()
    const [image, setImage] = useState<File | undefined>();
    const [imageTouched, setImageTouched] = useState(false);
    const imageRef = useRef<HTMLInputElement | null>(null);
    const [preview, setPreview] = useState(() => noImage);
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const formik = useFormik<IFormikValues>({
        initialValues: {
            name: "",
            phoneNumber: "",
            address: "",
        },
        validationSchema: CompanyFormSchema,
        onSubmit(values, formikHelpers) {
            if (props.edit && id) {
                dispatch(updateCompanyHandler({
                    data: values,
                    id: parseInt(id),
                }))
                return
            }
            dispatch(addCompanyHandler({
                ...values,
                logo: image!
            }))
        },
    })

    useEffect(() => {
        if (image) {
            const objectUrl = URL.createObjectURL(image);
            setPreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, [image]);


    useEffect(() => {
        if (props.edit && id) {
            dispatch(fetchSingleCompanyHandler(parseInt(id)))
        }
        return () => {
            dispatch(resetAddCompany())
            dispatch(resetUpdateCompany())
        }
    }, [])



    const { company } = useAppSelector(state => state.fetchSingleCompany)
    const { isLoading: isUpdateCompanyLoading, isSuccessful: isUpdateCompanySuccessful, error: updateCompanyError } = useAppSelector(state => state.updateCompany)
    const { isLoading: isAddCompanyLoading, isSuccessful: isAddCompanySuccessful, error: addCompanyError } = useAppSelector(state => state.addCompany)

    useEffect(() => {
        if (company) {

            formik.setValues({
                address: company.address,
                name: company.name,
                phoneNumber: company.phoneNumber,
                email: company.email !== null ? company.email : undefined,
            })
            setPreview(`${imgURL}/${company.logo}`);
        }

    }, [company])

    return (
        <div>
            <NavBar routeName='Companies' />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isAddCompanyLoading || isUpdateCompanyLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Container className="my-10">
                <div className="flex justify-end my-1">
                    <CustomBreadCrumb>
                        <Link underline="none" className="hover:cursor-pointer" onClick={() => navigate('/companies')} color="gray">Companies</Link>
                        {!props.edit && <p className="font-bold">New</p>}
                        {props.edit && <p className="font-bold">Edit</p>}
                    </CustomBreadCrumb>
                </div>
                <h1 className='text-4xl'>Company Registration Form</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum orci vitae lacus lacinia elementum.</p>
                <div className="my-2">
                    {isUpdateCompanySuccessful && <Alert>Company Successfully Updated</Alert>}
                    {updateCompanyError && <Alert severity="error">{updateCompanyError}</Alert>}
                    {isAddCompanySuccessful && <Alert>Company Successfully Added</Alert>}
                    {addCompanyError && <Alert severity="error">{addCompanyError}</Alert>}
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className='mt-10 flex justify-center sm:block'>
                        <div className=''>
                            <input
                                accept='.png, .jpg, .jpeg'
                                onBlur={() => {
                                    setImageTouched(true)
                                }}
                                onChange={(e) => {
                                    if (e.target.files !== null) {
                                        setImage(e.target.files[0]);
                                    }
                                    e.target.value = ""
                                }}
                                ref={imageRef}
                                type="file"
                                style={{ display: "none" }}
                            />
                            <img src={preview} className="h-40 w-48 object-cover" />
                            <button onClick={() => imageRef.current?.click()} type="button" className='w-48 flex px-3 py-1 space-x-2 items-center justify-center '>
                                <div><CloudUploadIcon /></div>
                                <p>Select Logo <span className="text-red-500">*</span></p>
                            </button>
                            {imageTouched && !image && <p className="w-48 capitalize  text-xs text-center text-red-500 pl-2">image is required</p>}

                        </div>

                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 my-5 grow'>
                        <div>
                            <p>Name <span className="text-red-500">*</span></p>
                            <TextField
                                error={Boolean(formik.touched.name && formik.errors.name)}
                                placeholder="Name" fullWidth
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur} />
                            {formik.touched.name && <p className="text-xs text-red-500 pl-2">{formik.errors.name}</p>}
                        </div>
                        <div>
                            <p>Address <span className="text-red-500">*</span></p>
                            <TextField error={Boolean(formik.touched.address && formik.errors.address)} placeholder="Address" fullWidth name="address" value={formik.values.address} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            {formik.touched.address && <p className="text-xs text-red-500 pl-2">{formik.errors.address}</p>}
                        </div>
                        <div>
                            <p>Phone Number <span className="text-red-500">*</span></p>
                            <TextField error={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)} placeholder="+251901234567" fullWidth name="phoneNumber" value={formik.values.phoneNumber} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            {formik.touched.phoneNumber && <p className="text-xs text-red-500 pl-2">{formik.errors.phoneNumber}</p>}
                        </div>
                        <div>
                            <p>Email </p>
                            <TextField error={Boolean(formik.touched.email && formik.errors.email)} placeholder="Email" fullWidth name="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            {formik.touched.email && <p className="text-xs text-red-500 pl-2">{formik.errors.email}</p>}
                        </div>
                    </div>
                    <div className='space-x-2 flex justify-end'>
                        <CancelButton onClickHandler={() => { }} text="reset" />
                        <SubmitButton onClickHandler={() => {
                            if (!props.edit) {
                                setImageTouched(true)
                            }
                        }} text='submit' />
                    </div>
                </form>
            </Container>
        </div>
    )
};

export default AddEditCompanyPage;