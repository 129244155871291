import { Alert, Backdrop, Button, CircularProgress, Container, DialogActions, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CancelButton from '../components/CancelButton'
import NavBar from '../components/NavBar'
import SubmitButton from '../components/SubmitButton'
import noImage from "../images/no-image.jpg";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import { useFormik } from "formik";

import * as Yup from 'yup';
import { fetchCompaniesHandler } from '../slices/companies/fetch-companies'
import { registerUserHandler } from '../slices/users/register-user'
import { IRegisterUserRequestDto } from '../slices/users/type'

interface IFormikValues {
    firstName: string;
    lastName: string;
    middleName: string;
    birthDate: string;
    email: string;
    city?: string;
    subCity?: string;
    companyId?: string;
    position: string;
    phoneNumber?: string;
}

const phoneRegExp = /^\+[1-9]{3}[0-9]{9,10}$/

function _calculateAge(birthday: Date) {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}


const CompanyFormSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    position: Yup.string().required('Required'),
    middleName: Yup.string().required('Required'),
    birthDate: Yup.date()
        .required('Required')
        .test("birthDate", "Should be greater than 18", function (value) {
            if (value) {
                return _calculateAge(value) >= 18
            }
            return false;
        }),
    phoneNumber: Yup.string().matches(phoneRegExp, { message: "invalid phone number" }),
    email: Yup.string().email('Invalid email').required('Required'),
    companyId: Yup.number().required("Required")
});


const RegistrationPage = () => {
    const { data: { companies } } = useAppSelector(state => state.fetchCompanies)
    const { isLoading: isRegistrationLoading, isSuccessful: isRegistrationSuccessful, error: registrationError } = useAppSelector(state => state.registerUser)
    const [image, setImage] = useState<File | undefined>();
    const [imageTouched, setImageTouched] = useState(false);
    const imageRef = useRef<HTMLInputElement | null>(null);
    const [preview, setPreview] = useState(() => noImage);
    const dispatch = useAppDispatch()

    const formik = useFormik<IFormikValues>({
        initialValues: {
            birthDate: "",
            email: "",
            firstName: "",
            lastName: "",
            middleName: "",
            companyId: "",
            position: ""
        },
        validationSchema: CompanyFormSchema,
        onSubmit(values, formikHelpers) {
            if (!image) {
                return
            }
            const body: IRegisterUserRequestDto = {
                ...values,
                companyId: parseInt(values.companyId!),
                image: image,
            }
            console.log(body)
            dispatch(registerUserHandler(body))
        },
    })
    useEffect(() => {
        if (image) {
            const objectUrl = URL.createObjectURL(image);
            setPreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, [image]);

    useEffect(() => {
        dispatch(fetchCompaniesHandler({ page: 0, limit: 1000}))
    }, [])

    return (
        <div>
            <NavBar routeName='' />
            <Container className='my-10'>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isRegistrationLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <h1 className='text-4xl'>Registration Form</h1>
                <p>Please use the form to contact us. Please allow up to ten business days to receive an email response. The Ethiopian Football federation Copyright Office continues to provide registration, recordation, and information services</p>
                <div className="my-2">
                    {isRegistrationSuccessful && <Alert>You are registered successfully. You will recieve a confirmation email.</Alert>}
                    {registrationError && <Alert severity="error">{registrationError}</Alert>}
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className='mt-10 flex justify-center sm:block'>
                        <div className=''>
                            <input
                                accept='.png, .jpg, .jpeg'
                                onBlur={() => {
                                    setImageTouched(true)
                                }}
                                onChange={(e) => {
                                    if (e.target.files !== null) {
                                        setImage(e.target.files[0]);
                                    }
                                    e.target.value = ""
                                }}
                                ref={imageRef}
                                type="file"
                                style={{ display: "none" }}
                            />
                            <img src={preview} className="h-40 w-48 object-cover" />
                            <button onClick={() => imageRef.current?.click()} type="button" className='w-48 flex px-3 py-1 space-x-2 items-center justify-center '>
                                <div><CloudUploadIcon /></div>
                                <p>Select Image <span className="text-red-500">*</span></p>
                            </button>
                            {imageTouched && !image && <p className="w-48 capitalize  text-xs text-center text-red-500 pl-2">image is required</p>}
                        </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 my-5 grow'>
                        <div>
                            <p>First Name <span className="text-red-500">*</span></p>
                            <TextField
                                placeholder="First Name"
                                fullWidth
                                name="firstName"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.firstName && formik.errors.firstName)}

                            />
                            {formik.touched.firstName && <p className="text-xs text-red-500 pl-2">{formik.errors.firstName}</p>}
                        </div>
                        <div>
                            <p>Middle Name <span className="text-red-500">*</span></p>
                            <TextField
                                placeholder="Middle Name"
                                fullWidth
                                name="middleName"
                                value={formik.values.middleName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.middleName && formik.errors.middleName)}

                            />
                            {formik.touched.middleName && <p className="text-xs text-red-500 pl-2">{formik.errors.middleName}</p>}

                        </div>
                        <div>
                            <p>Last Name <span className="text-red-500">*</span></p>
                            <TextField
                                placeholder="Last Name"
                                fullWidth
                                name="lastName"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.lastName && formik.errors.lastName)}

                            />
                            {formik.touched.lastName && <p className="text-xs text-red-500 pl-2">{formik.errors.lastName}</p>}

                        </div>
                        <div>
                            <p>Birth Date<span className="text-red-500">*</span></p>
                            <TextField
                                type="date"
                                placeholder="Birth Date"
                                fullWidth
                                name="birthDate"
                                value={formik.values.birthDate}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.birthDate && formik.errors.birthDate)}
                            />
                            {formik.touched.birthDate && <p className="text-xs text-red-500 pl-2">{formik.errors.birthDate}</p>}

                        </div>
                        <div >
                            <p>Email<span className="text-red-500">*</span></p>
                            <TextField
                                error={Boolean(formik.touched.email && formik.errors.email)}
                                placeholder="Email"
                                fullWidth
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.email && <p className="text-xs text-red-500 pl-2">{formik.errors.email}</p>}

                        </div>
                        <div>
                            <p>City</p>
                            <TextField
                                error={Boolean(formik.touched.city && formik.errors.city)}
                                placeholder="City"
                                fullWidth
                                name="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.city && <p className="text-xs text-red-500 pl-2">{formik.errors.city}</p>}

                        </div>
                        <div>
                            <p>Sub City</p>
                            <TextField
                                error={Boolean(formik.touched.subCity && formik.errors.subCity)}
                                placeholder="Sub City"
                                fullWidth
                                name="subCity"
                                value={formik.values.subCity}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.subCity && <p className="text-xs text-red-500 pl-2">{formik.errors.subCity}</p>}

                        </div>
                        <div>
                            <p>Organization Name <span className="text-red-500">*</span></p>
                            <TextField
                                error={Boolean(formik.touched.companyId && formik.errors.companyId)}
                                placeholder="Organization"
                                fullWidth
                                select
                                name="companyId"
                                value={formik.values.companyId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                {
                                    companies.map(company => {
                                        return <MenuItem value={company.id}>{company.name}</MenuItem>;
                                    })
                                }
                            </TextField>
                            {formik.touched.companyId && <p className="text-xs text-red-500 pl-2">{formik.errors.companyId}</p>}

                        </div>

                        <div>
                            <p>Position <span className="text-red-500">*</span></p>
                            <TextField
                                error={Boolean(formik.touched.position && formik.errors.position)}
                                placeholder="Position"
                                fullWidth
                                name="position"
                                value={formik.values.position}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.position && <p className="text-xs text-red-500 pl-2">{formik.errors.position}</p>}

                        </div>
                        <div>
                            <p>Phone No.</p>
                            <TextField
                                error={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                                placeholder="Phone No."
                                fullWidth
                                name="phoneNumber"
                                value={formik.values.phoneNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.phoneNumber && <p className="text-xs text-red-500 pl-2">{formik.errors.phoneNumber}</p>}

                        </div>
                    </div>
                    <div className='space-x-2 flex justify-end'>
                        <CancelButton onClickHandler={() => { }} text="reset" />
                        <SubmitButton onClickHandler={() => { setImageTouched(true) }} text='submit' />
                    </div>
                </form>
            </Container>
        </div>
    )
}

export default RegistrationPage