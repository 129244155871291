import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAxiosInstance } from "../../helper";
import { AppDispatch, RootState } from "../../store";
import { logout } from "../auth/login";
import { IGenericState } from "../common.type";
import { fetchCompaniesHandler } from "./fetch-companies";
import { ICreateCompanyRequestDto, IFetchCompaniesState } from "./type";

export const deleteCompanyHandler = createAsyncThunk<
  void,
  number,
  { dispatch: AppDispatch; state: RootState }
>("delete_company", async (id, { dispatch, getState }) => {
  try {
    const { login } = getState();
    await getAxiosInstance(login.data?.token).delete(`/companies/${id}`);
    dispatch(fetchCompaniesHandler({}));
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.data) {
      if (e.response.status === 401) {
        dispatch(logout());
      }
      let { message } = e.response.data as { message: string };
      throw new Error(message);
    } else {
      throw e;
    }
  }
});

const initialState: IGenericState = {
  isLoading: false,
  isSuccessful: false,
};
const deleteCompanySlice = createSlice({
  name: "delete_company",
  initialState,
  reducers: {
    resetDeleteCompany(state) {
      state.error = undefined;
      state.isLoading = false;
      state.isSuccessful = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(deleteCompanyHandler.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(deleteCompanyHandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;
      state.isSuccessful = true;
    });
    builder.addCase(deleteCompanyHandler.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetDeleteCompany } = deleteCompanySlice.actions;
export default deleteCompanySlice.reducer;
