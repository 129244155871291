import { Container, Link, Stack } from '@mui/material'
import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from '@mui/icons-material/Telegram';

interface Iprops {
    children: React.ReactNode
}
const SocialMediaIconsWrapper = ({ children }: Iprops) => {
    return (
        <div className='w-9 h-9 flex items-center justify-center sm:w-11 sm:h-11 sm:p-2  border  rounded-full'>
            {children}
        </div>
    );
}
const SocialMedias = () => {
    const iconStyle = "text-white "
    const iconSize = { fontSize: { sm: 24, xs: 18 } }
    return (
        <Container className="space-y-4">
            <div className='flex justify-center space-x-2 mt-10'>
                <SocialMediaIconsWrapper>
                    <Link href="">
                        <FacebookIcon sx={iconSize} className={iconStyle} />
                    </Link>
                </SocialMediaIconsWrapper>
                <SocialMediaIconsWrapper>
                    <Link href="">
                        <TelegramIcon sx={iconSize} className={iconStyle} />
                    </Link>
                </SocialMediaIconsWrapper>
                <SocialMediaIconsWrapper>
                    <Link href="">
                        <InstagramIcon sx={iconSize} className={iconStyle} />
                    </Link>
                </SocialMediaIconsWrapper>
            </div>
            <p className="text-sm font-thin text-center ">
                Copyright © 2022
            </p>
        </Container>
    )
}

export default SocialMedias
