import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAxiosInstance } from "../../helper";
import { AppDispatch, RootState } from "../../store";
import { logout } from "../auth/login";
import { IGenericState } from "../common.type";
import { GetProfileResponseDto, GetProfileState } from "./type";

export const getProfileHandler = createAsyncThunk<
  GetProfileResponseDto,
  void,
  { dispatch: AppDispatch; state: RootState }
>("get_profile", async (payload, { dispatch, getState }) => {
  try {
    const response = await getAxiosInstance().get(`/profile`);
    const data = response.data as GetProfileResponseDto;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.data) {
      if (e.response.status === 401) {
        dispatch(logout());
      }
      let { message } = e.response.data as { message: string };
      throw new Error(message);
    } else {
      throw e;
    }
  }
});

const initialState: GetProfileState = {
  isLoading: false,
  data: null,
};
const getProfileSlice = createSlice({
  name: "get_profile",
  initialState,
  reducers: {
    resetGetProfile(state) {
      state.error = undefined;
      state.isLoading = false;
      state.data = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(getProfileHandler.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getProfileHandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;
      if (action.payload) {
        state.data = {
          assemblyName:action.payload.assemblyTitle,
          assemblyNumber:action.payload.assemblyNumber,
          id:action.payload.id,
          image:action.payload.pic
        }
      }
    });
    builder.addCase(getProfileHandler.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetGetProfile } = getProfileSlice.actions;
export default getProfileSlice.reducer;
