import React from 'react'

interface IProps {
    text?: string;
    onClickHandler: () => void;
}
const CancelButton = (props: IProps) => {
    return (
        <button type="button" onClick={props.onClickHandler} className='px-4 py-1 rounded-md border capitalize border-black'>{props.text || "cancel"}</button>
    )
}

export default CancelButton