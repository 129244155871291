import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAxiosInstance } from "../../helper";
import { AppDispatch, RootState } from "../../store";
import { logout } from "../auth/login";
import { IGenericState } from "../common.type";
import { ChangeProfileRequestDto } from "./type";

export const changeProfileHandler = createAsyncThunk<
  void,
  ChangeProfileRequestDto,
  { dispatch: AppDispatch; state: RootState }
>("change_profile", async (payload, { dispatch, getState }) => {
  try {
    const { login } = getState();
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      if (key !== "pic") {
        formData.append(key, value);
      }
    }
    if (payload.pic) {
      formData.append("image", payload.pic);
    }
    await getAxiosInstance(login.data?.token).put(
      `/profile/change`,
      formData
    );
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.data) {
      if (e.response.status === 401) {
        dispatch(logout());
      }
      let { message } = e.response.data as { message: string };
      throw new Error(message);
    } else {
      throw e;
    }
  }
});

const initialState: IGenericState = {
  isLoading: false,
  isSuccessful: false,
};
const changeProfileSlice = createSlice({
  name: "change_profile",
  initialState,
  reducers: {
    resetChangeProfile(state) {
      state.error = undefined;
      state.isLoading = false;
      state.isSuccessful = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(changeProfileHandler.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(changeProfileHandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;
      state.isSuccessful = true;
    });
    builder.addCase(changeProfileHandler.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetChangeProfile } = changeProfileSlice.actions;
export default changeProfileSlice.reducer;
