import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAxiosInstance } from "../../helper";
import { AppDispatch, RootState } from "../../store";
import { logout } from "../auth/login";
import { IGenericState } from "../common.type";
import { ICreateCompanyRequestDto, IFetchCompaniesState } from "./type";

export const addCompanyHandler = createAsyncThunk<
  void,
  ICreateCompanyRequestDto,
  { state: RootState, dispatch: AppDispatch}
>("add_company", async (data, { getState, dispatch }) => {
  try {
    const { login } = getState()
    const formData = new FormData();
    formData.append("image", data.logo);
    formData.append("address", data.address);
    formData.append("name", data.name);
    formData.append("phoneNumber", data.phoneNumber);
    data.email && formData.append("email", data.email);
    await getAxiosInstance(login.data?.token).post(`/companies`, formData);
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.data) {
      if (e.response.status === 401) {
        dispatch(logout());
      }
      let { message } = e.response.data as { message: string };
      throw new Error(message);
    } else {
      throw e;
    }
  }
});

const initialState: IGenericState = {
  isLoading: false,
  isSuccessful: false,
};
const addCompanySlice = createSlice({
  name: "add_company",
  initialState,
  reducers: {
    resetAddCompany(state) {
      state.error = undefined;
      state.isLoading = false;
      state.isSuccessful = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(addCompanyHandler.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(addCompanyHandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;
      state.isSuccessful = true;
    });
    builder.addCase(addCompanyHandler.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetAddCompany } = addCompanySlice.actions;
export default addCompanySlice.reducer;
