import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAxiosInstance } from "../../helper";
import { ILogin, ILoginState } from "./type";
const persistedState = localStorage.getItem("data");

const initialState: ILoginState = {
  isLoading: false,
  data: persistedState ? (JSON.parse(persistedState) as ILogin) : undefined,
};
interface ILoginThunkData {
  name: string;
  password: string;
}
export const login = createAsyncThunk<ILogin, ILoginThunkData>(
  "login",
  async ({ name, password }) => {
    try {
      const response = await getAxiosInstance().post(`/auth/login`, {
        username: name,
        password,
      });
      const data = response.data as ILogin;
      localStorage.setItem("data", JSON.stringify(data));
      return data;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data) {
        let { message } = e.response.data as { message: string };
        throw new Error(message);
      } else {
        throw e;
      }
    }
  }
);
const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logout(state) {
      localStorage.removeItem("data");
      state.data = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { logout } = loginSlice.actions;
export default loginSlice.reducer;
