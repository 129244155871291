import React from 'react'

interface IProps {
    text?: string;
    onClickHandler?: () => void;
}
const SubmitButton = (props: IProps) => {
    return (
        <button onClick={props.onClickHandler} type="submit" className='px-4 py-1 rounded-md text-white bg-gray-800 capitalize'>{props.text || "submit"}</button>
    )
}

export default SubmitButton