import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAxiosInstance } from "../../helper";
import {
  IFetchSingleUserState,
  IFetchUsersResponseDto,
  IFetchUsersState,
  IUser,
} from "./type";

export const fetchUserByCodeHandler = createAsyncThunk<IUser, string>(
  "fetch_user_by_code",
  async (code) => {
    try {
      const response = await getAxiosInstance().get(`/users/${code}`);
      return response.data as IUser;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data) {
        let { message } = e.response.data as { message: string };
        throw new Error(message);
      } else {
        throw e;
      }
    }
  }
);

const initialState: IFetchSingleUserState = {
  isLoading: false,
};
const fetchUserByCodeSlice = createSlice({
  name: "fetch_user_by_code",
  initialState,
  reducers: {
    resetFetchSingleUser(state) {
      state.user = undefined;
      state.error = undefined;
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUserByCodeHandler.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchUserByCodeHandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;
      state.user = action.payload;
    });
    builder.addCase(fetchUserByCodeHandler.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetFetchSingleUser } = fetchUserByCodeSlice.actions;
export default fetchUserByCodeSlice.reducer;
