import addCompany from "./add-company";
import deleteCompany from "./delete-company";
import fetchCompanies from "./fetch-companies";
import fetchSingleCompany from "./fetch-single-company";
import updateCompany from "./update-company";

export default {
  fetchCompanies: fetchCompanies,
  addCompany: addCompany,
  deleteCompany: deleteCompany,
  fetchSingleCompany: fetchSingleCompany,
  updateCompany: updateCompany,
};
