import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import PrivateRoute from './components/PrivateRoute';
import AddEditCompanyPage from './pages/AddEditCompanyPage';
import ApprovalsPage from './pages/ApprovalsPage';
import CompaniesPage from './pages/CompaniesPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import PermitsPage from './pages/PermitsPage';
import ProfilePage from './pages/ProfilePage';
import RegistrationPage from './pages/RegistrationPage';
import UnknownRequest from './pages/UnkownRequest';
import VerfiyEmailPage from './pages/VerfiyEmailPage';
function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/registration' element={<RegistrationPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/companies' element={<PrivateRoute><CompaniesPage /></PrivateRoute>} />
        <Route path='/companies/new' element={<PrivateRoute><AddEditCompanyPage edit={false} /></PrivateRoute>} />
        <Route path='/companies/edit/:id' element={<PrivateRoute><AddEditCompanyPage edit={true} /></PrivateRoute>} />
        <Route path="/verify/:token" element={<VerfiyEmailPage />} />
        <Route path='/permits' element={<PermitsPage />} />
        <Route path='/profile' element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
        <Route path='/approvals' element={<PrivateRoute><ApprovalsPage /></PrivateRoute>} />
        <Route path="/404" element={<UnknownRequest />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </Router>
  );
}

export default App;
