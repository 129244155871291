import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';

interface IProps {
    placeholder?: string;
    onSearchHandler: (code: string) => void;
}
const Search = (props: IProps) => {
    const [code, setCode] = useState("")
    return (
        <div className='flex items-center p-2 border rounded-md border-gray-300'>
            <input value={code} onChange={(e) => setCode(e.target.value)} placeholder={props.placeholder} type="text" className='w-full p-2 outline-none' />
            <IconButton onClick={() => props.onSearchHandler(code)}>
                <SearchIcon />
            </IconButton>
        </div>
    )
}

export default Search