import axios, { AxiosInstance } from "axios";

export const baseURL = "https://efftransferonline.net/api/v4";
export const imgURL = "https://efftransferonline.net/accredition-uploads";

export const getAxiosInstance = (token?: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: baseURL,
    headers: {
      authorization: token ? token : "",
    },
  });
  return instance;
};