import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAxiosInstance } from "../../helper";
import { AppDispatch, RootState } from "../../store";
import { logout } from "../auth/login";
import { IFetchUsersResponseDto, IFetchUsersState } from "./type";

interface IFetchUsersThunkData {
  page?: number;
  limit?: number;
  status?: string;
  code?: string;
}
export const fetchUsersHandler = createAsyncThunk<
  IFetchUsersResponseDto,
  IFetchUsersThunkData,
  { state: RootState, dispatch: AppDispatch}
>("fetch_users", async (data, { getState, dispatch }) => {
  try {
    const { login } = getState()
    let query = [];
    if (data.limit) {
      query.push(`limit=${data.limit}`);
    }
    if (data.page) {
      query.push(`page=${data.page}`);
    }
    if (data.status) {
      query.push(`status=${data.status}`);
    }
    if (data.code) {
      query.push(`code=${data.code}`);
    }
    const response = await getAxiosInstance(login.data?.token).get(
      `/users?${query.join("&")}`
    );
    return response.data as IFetchUsersResponseDto;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.data) {
      if (e.response.status === 401) {
        dispatch(logout());
      }
      let { message } = e.response.data as { message: string };
      throw new Error(message);
    } else {
      throw e;
    }
  }
});

const initialState: IFetchUsersState = {
  data: {
    users: [],
    count: 0,
  },
  isLoading: false,
};
const fetchUsersSlice = createSlice({
  name: "fetch_users",
  initialState,
  reducers: {
    resetFetchUsers(state) {
      state.data.users = [];
      state.data.count = 0;
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUsersHandler.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchUsersHandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;
      state.data = action.payload;
    });
    builder.addCase(fetchUsersHandler.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetFetchUsers } = fetchUsersSlice.actions;
export default fetchUsersSlice.reducer;
