import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAxiosInstance } from "../../helper";
import { AppDispatch, RootState } from "../../store";
import { logout } from "../auth/login";
import { IGenericState } from "../common.type";
import { IFetchCompaniesState, IUpdateCompanyRequestDto } from "./type";

interface IUpdateCompanyThunkData {
  data: IUpdateCompanyRequestDto;
  id: number;
}
export const updateCompanyHandler = createAsyncThunk<
  void,
  IUpdateCompanyThunkData,
  { state: RootState, dispatch: AppDispatch}
>("update_company", async (data, { getState, dispatch }) => {
  try {
    const { login } = getState();

    const formData = new FormData();
    for (let [key, value] of Object.entries(data.data)) {
      if (value !== undefined) {
        formData.append(key, value);
      }
    }
    await getAxiosInstance(login.data?.token).patch(`/companies/${data.id}`, formData);
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.data) {
      if (e.response.status === 401) {
        dispatch(logout());
      }
      let { message } = e.response.data as { message: string };
      throw new Error(message);
    } else {
      throw e;
    }
  }
});

const initialState: IGenericState = {
  isLoading: false,
  isSuccessful: false,
};
const updateCompanySlice = createSlice({
  name: "update_company",
  initialState,
  reducers: {
    resetUpdateCompany(state) {
      state.error = undefined;
      state.isLoading = false;
      state.isSuccessful = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(updateCompanyHandler.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(updateCompanyHandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;
      state.isSuccessful = true;
    });
    builder.addCase(updateCompanyHandler.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetUpdateCompany } = updateCompanySlice.actions;
export default updateCompanySlice.reducer;
