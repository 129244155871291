import { configureStore } from "@reduxjs/toolkit";
import companies from "./slices/companies";
import users from "./slices/users";
import auth from "./slices/auth";
import profile from "./slices/profile";
export const store = configureStore({
  devTools: true,
  reducer: {
    ...companies,
    ...users,
    ...auth,
    ...profile
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
