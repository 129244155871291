import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAxiosInstance } from "../../helper";
import { IGenericState } from "../common.type";
import { IRegisterUserRequestDto } from "./type";

export const registerUserHandler = createAsyncThunk<
  void,
  IRegisterUserRequestDto
>("register_user", async (data) => {
  try {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      console.log(key, value);
      formData.append(key, value);
    }
    console.log(formData);
    await getAxiosInstance().post(`/users`, formData);
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.data) {
      let { message } = e.response.data as { message: string };
      throw new Error(message);
    } else {
      throw e;
    }
  }
});

const initialState: IGenericState = {
  isLoading: false,
  isSuccessful: false,
};
const registerUserSlice = createSlice({
  name: "register_user",
  initialState,
  reducers: {
    resetRegisterUser(state) {
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(registerUserHandler.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(registerUserHandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;
      state.isSuccessful = true;
    });
    builder.addCase(registerUserHandler.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetRegisterUser } = registerUserSlice.actions;
export default registerUserSlice.reducer;
