import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAxiosInstance } from "../../helper";
import { AppDispatch, RootState } from "../../store";
import { logout } from "../auth/login";
import { ICompany, IFetchSingleCompanyState } from "./type";

export const fetchSingleCompanyHandler = createAsyncThunk<
  ICompany,
  number,
  { state: RootState , dispatch: AppDispatch}
>(
  "fetch_single_company",

  async (id, { getState, dispatch }) => {
    try {
      const { login } = getState();
      const response = await getAxiosInstance(login.data?.token).get(
        `/companies/${id}`
      );
      return response.data as ICompany;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data) {
        if (e.response.status === 401) {
          dispatch(logout());
        }
        let { message } = e.response.data as { message: string };
        throw new Error(message);
      } else {
        throw e;
      }
    }
  }
);

const initialState: IFetchSingleCompanyState = {
  isLoading: false,
};
const fetchSingleCompanySlice = createSlice({
  name: "fetch_single_company",
  initialState,
  reducers: {
    resetFetchSingleCompany(state) {
      state.company = undefined;
      state.error = undefined;
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchSingleCompanyHandler.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchSingleCompanyHandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;
      state.company = action.payload;
    });
    builder.addCase(fetchSingleCompanyHandler.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetFetchSingleCompany } = fetchSingleCompanySlice.actions;
export default fetchSingleCompanySlice.reducer;
