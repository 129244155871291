import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAxiosInstance } from "../../helper";
import { AppDispatch, RootState } from "../../store";
import { IGenericState } from "../common.type";
import { fetchUsersHandler } from "../users/fetch-users";
import { IChangeStatusRequestDto, IVerifyEmailRequestDto } from "./type";

interface IChangeStatusThunkData {
  id: number;
  code: string;
  payload: IChangeStatusRequestDto;
}
export const changeStatusHandler = createAsyncThunk<
  void,
  IChangeStatusThunkData,
  { dispatch: AppDispatch, state: RootState}
>("change-status", async (data, { dispatch, getState }) => {
  try {
    const { login } = getState()
    await getAxiosInstance(login.data?.token).patch(
      `/users/${data.id}/status`,
      data.payload
    );
    dispatch(fetchUsersHandler({  }));
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.data) {
      let { message } = e.response.data as { message: string };
      throw new Error(message);
    } else {
      throw e;
    }
  }
});

const initialState: IGenericState = {
  isLoading: false,
  isSuccessful: false,
};
const changeStatusSlice = createSlice({
  name: "change-status",
  initialState,
  reducers: {
    resetChangeStatus(state) {
      state.error = undefined;
      state.isLoading = false;
      state.isSuccessful = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(changeStatusHandler.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(changeStatusHandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;
      state.isSuccessful = true;
    });
    builder.addCase(changeStatusHandler.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetChangeStatus } = changeStatusSlice.actions;
export default changeStatusSlice.reducer;
