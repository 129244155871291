import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";

interface IProps {
    handleCancel: () => void;
    handleDelete: () => void;
    openConfirmation: boolean;

}
const Confirmation = (props: IProps) => {
    const { handleCancel, handleDelete, openConfirmation } = props
    return (
        <Dialog open={openConfirmation} fullWidth>
            <DialogContent>
                <div className="bg-white  px-5 space-y-1 rounded-md">
                    <div className="flex justify-center">
                        <span className="bg-red-100 text-red-700 p-4 rounded-full">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="1"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                            </svg>
                        </span>
                    </div>
                    <div className="text-lg text-center font-semibold">Are you sure ?</div>
                    <div className="text-sm text-gray-500 text-center">
                        Are you sure you want to delete this item ? You can't undo this
                        action.
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button size="small" variant="outlined" onClick={handleCancel}>Cancel</Button>
                <Button size="small" variant="contained" onClick={handleDelete}>Okay</Button>
            </DialogActions>
        </Dialog>
    );
};

export default Confirmation;