import { Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useEffect } from 'react'
import PrintIcon from '@mui/icons-material/Print'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import { fetchAllUsersHandler } from '../../slices/users/fetchAllUsers'

interface IProps {
    closeReport: () => void;
    openDialog: boolean;
}
const ApprovalReport = (props: IProps) => {
    const dispatch = useAppDispatch()
    const { data: { users } } = useAppSelector(state => state.fetchAllUsers)

    useEffect(() => {
        dispatch(fetchAllUsersHandler())
    }, [])
    return (
        <Dialog onClose={() => props.closeReport()} open={props.openDialog} fullWidth maxWidth="lg">
            <DialogTitle>
                <div className='mt-5 flex justify-end print:hidden'>
                    <button onClick={() => window.print()} className='flex space-x-2 capitalize outline-none items-center'>
                        <PrintIcon fontSize='small' />
                        <div className='text-sm'>Print</div>
                    </button>
                </div>
            </DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 900 }}>First Name</TableCell>
                            <TableCell sx={{ fontWeight: 900 }}>Middle Name</TableCell>
                            <TableCell sx={{ fontWeight: 900 }}>Last Name</TableCell>
                            <TableCell sx={{ fontWeight: 900 }}>Organization Name</TableCell>
                            <TableCell sx={{ fontWeight: 900 }}>Position</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => {
                            return (
                                <TableRow>
                                    <TableCell>{user.firstName}</TableCell>
                                    <TableCell>{user.middleName}</TableCell>
                                    <TableCell>{user.lastName}</TableCell>
                                    <TableCell>{user.company.name}</TableCell>
                                    <TableCell>{user.position}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    )
}

export default ApprovalReport