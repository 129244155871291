import React from "react";
import { Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface IProps {
  children: React.ReactNode;
}
const CustomBreadCrumb = (props: IProps) => {
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      {props.children}
    </Breadcrumbs>
  );
};

export default CustomBreadCrumb;
