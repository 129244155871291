import { Alert, CircularProgress, Container, Link } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import logo from "../images/logo.png"
import { resetVerifyEmail, verifyEmailHandler } from '../slices/auth/verify-email'
const VerfiyEmailPage = () => {
    const navigate = useNavigate()
    const { isLoading: isVefifyingEmailLoading, isSuccessful: isVerifyingEmailSuccessful, error: verifyEmailError } = useAppSelector(state => state.verifyEmail)
    const dispatch = useAppDispatch()
    const { token } = useParams()
    useEffect(() => {
        if (token) {
            dispatch(verifyEmailHandler({
                token: token
            }))
        }
        return () => {
            dispatch(resetVerifyEmail())
        }
    }, [])
    return (
        <div className='h-screen w-screen flex items-center justify-center'>
            <div className='space-y-2 flex flex-col items-center p-10'>
                <img src={logo} className="h-40 w-40" />
                <p>EFF | General Elective Assembly</p>
                <div className='mt-10'>
                    {isVefifyingEmailLoading && <div className='flex items-center space-x-2'><CircularProgress /> <span>verfiying ...</span> </div>}
                    {isVerifyingEmailSuccessful && < Alert > Your email is successfully verified. Please wait for the admin to approve your request. You can go back to <Link onClick={() => navigate('/')}>Home</Link></Alert>}
                    {verifyEmailError && < Alert severity='error' > {verifyEmailError} You can go back to <Link onClick={() => navigate('/')}>Home</Link></Alert>}
                </div>
            </div>
        </div >
    )
}

export default VerfiyEmailPage