import { useEffect, useState } from "react";

const useViewport = () => {
  const [viewPort, setViewPort] = useState(() => ({
    height: window.innerHeight,
    width: window.innerWidth,
  }));
  useEffect(() => {
    const cb = () => {
      setViewPort({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", cb);
    return () => {
      window.removeEventListener("resize", cb);
    };
  }, []);

  return viewPort;
};

export default useViewport;
