import { Button, Container, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddEditCompany from './AddEditCompanyPage';
import CompanyRow from '../components/companies/CompanyRow';

import NavBar from '../components/NavBar'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { fetchCompaniesHandler } from '../slices/companies/fetch-companies';
import { ICompany } from '../slices/companies/type';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

const CompaniesPage = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isLoading: isFetchCompaniesLoading, data: fetchCompaniesData, error: fetchCompaniesError } = useAppSelector(state => state.fetchCompanies)
  useEffect(() => {
    dispatch(fetchCompaniesHandler({ limit, page }))
  }, [page, limit])


  const addCompanyHandler = ()=>{
    navigate("/companies/new")
  }

  return (
    <div>
      <NavBar routeName='Companies' />
      <Container className='mt-10'>
        <div className='flex justify-end my-2'>
          <Button onClick={addCompanyHandler} startIcon={<AddIcon />}>
            Add Company
          </Button>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fetchCompaniesData.companies.map(company => {
                return <CompanyRow company={company} />
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={fetchCompaniesData.count}
            rowsPerPage={limit}
            page={page}
            onPageChange={(event, page) => {
              setPage(page);
            }}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value));
            }}
          />
        </TableContainer>
      </Container>
    </div>

  )
}

export default CompaniesPage