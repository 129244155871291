import { Alert, Avatar, Box, Button, Container, CssBaseline, TextField, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import jwtDecode from 'jwt-decode';
import { login } from '../slices/auth/login';
const LoginPage = () => {
    const [name, setName] = React.useState("");
    const [password, setPassword] = React.useState("");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { data, error: loginError, isLoading } = useAppSelector(state => state.login)

    React.useEffect(() => {
        if (data) {
            const { role } = jwtDecode(data.token) as { role: string };
            if (role === "ADMIN") {
                navigate("/");
            }
        }
    }, [data]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(
            login({
                name,
                password,
            })
        );
    };

    return (
        <div>
            <NavBar routeName='' />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Name"
                            name="name"
                            autoFocus
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={isLoading}
                        >
                            {isLoading ? "Logging ..." : "Sign In"}
                        </Button>
                        {loginError && <Alert severity='error'>{loginError}</Alert>}

                    </Box>
                </Box>
            </Container>
        </div>
    )
}

export default LoginPage