import { Container, Divider, Link } from '@mui/material'
import React from 'react'
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import logo from "../images/logo.png"
import SocialMedias from './SocialMedias';

import { MapContainer, Marker, TileLayer, useMap, Popup } from 'react-leaflet'
import { LatLng } from 'leaflet';
import L from "leaflet";

const locationIcon = L.divIcon({
    html: `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-14 h-14 text-green-800">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
  
      `,
});

const Footer = () => {
    const position = new LatLng(9.015868244069733, 38.76875468387809);
    return (
        <div className='pt-10 pb-5 bg-gray-800 mt-10 text-white'>
            <Container >
                <div className='grid sm:grid-cols-3 '>
                    <div className='hidden sm:flex justify-center items-center'>
                        <MapContainer className='h-52 w-full rounded-md' center={position} zoom={16} scrollWheelZoom={false}>
                            <TileLayer
                                attribution=''
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={position} icon={locationIcon}>
                            </Marker>

                        </MapContainer>

                    </div>
                    <div className='hidden sm:flex text-sm space-y-2 text-gray-300  flex-col items-center'>
                        <p>Registration</p>
                        <p>Check Status</p>
                    </div>
                    <div className='text-sm space-y-2 text-gray-300'>
                        <div className='space-y-2 flex flex-col items-center sm:block'>
                            <Link href='https://goo.gl/maps/3ojetB8UE8z6P1Jd9' underline='none' >
                                <div className='space-x-2 flex items-center text-gray-300'>
                                    <LocationOnIcon />
                                    <p>Ethiopia, Addis Ababa</p>
                                </div>
                            </Link>
                            <div className='space-x-2 flex items-center'>
                                <EmailIcon />
                                <p>info@efootballfederation.com</p>
                            </div>
                            <div className='space-x-2 flex items-center'>
                                <LocalPhoneIcon />
                                <p>011 515 6205</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="my-5">
                    <Divider color='white' className='hidden sm:block' />
                </div>
                <SocialMedias />
            </Container>
        </div>
    )
}

export default Footer