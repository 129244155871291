import { Avatar, IconButton, TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { baseURL } from '../../helper';
import { ICompany } from '../../slices/companies/type';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Confirmation from '../Confirmation';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { deleteCompanyHandler } from '../../slices/companies/delete-company';
import { useNavigate } from 'react-router-dom';

interface IProps {
    company: ICompany
}
const CompanyRow = (props: IProps) => {
    const { company } = props
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    return (
        <TableRow>
            <Confirmation
                openConfirmation={openConfirmation}
                handleCancel={() => { setOpenConfirmation(false) }}
                handleDelete={() => { 
                    dispatch(deleteCompanyHandler(company.id))
                    setOpenConfirmation(false)
                 }} />
            <TableCell align="left">
                <Avatar alt={company.name.toUpperCase()} src={`${baseURL}/${company.logo}`} />
            </TableCell>
            <TableCell align="left" className='capitalize'>{company.name}</TableCell>
            <TableCell align="left" className='capitalize'>
                {company.address}
            </TableCell>
            <TableCell align="left">
                {company.email || "--"}
            </TableCell>
            <TableCell align="left">
                {company.phoneNumber || "--"}
            </TableCell>
            <TableCell align="left">
                <IconButton onClick={() => navigate(`/companies/edit/${company.id}`)}>
                    <EditIcon fontSize='small' />
                </IconButton>
                <IconButton onClick={() => setOpenConfirmation(true)}>
                    <DeleteIcon fontSize='small' />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default CompanyRow