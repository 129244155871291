import { Button, Container, FormControl, InputLabel, MenuItem, Pagination, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ApprovalItem from '../components/approval/ApprovalItem'
import ApprovalReport from '../components/approval/ApprovalReport'
import NavBar from '../components/NavBar'
import Search from '../components/Search'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import { fetchUsersHandler, resetFetchUsers } from '../slices/users/fetch-users'

const ApprovalsPage = () => {
    const dispatch = useAppDispatch()
    const { data: { users, count }, isLoading: isUserDataFetching, error: fetchUserError } = useAppSelector(state => state.fetchUsers)
    const [code, setCode] = useState("")
    const [status, setStatus] = useState("all")
    const limit = 5;
    useEffect(() => {
        dispatch(fetchUsersHandler({
            code,
            status: status === "all" ? "" : status
        }))
    }, [code, status])

    useEffect(() => {
        return () => {
            dispatch(resetFetchUsers())
        }
    }, [])

    const [openReport, setOpenReport] = useState(false)
    return (
        <div>
            <NavBar routeName='Approvals' />
            <ApprovalReport openDialog={openReport} closeReport={() => setOpenReport(false)} />
            <Container className='mt-10'>
                <div className='grid grid-cols-1 sm:grid-cols-3 items-center gap-1'>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            onChange={(e) => {
                                setStatus(e.target.value)
                            }}
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="APPROVED">Approved</MenuItem>
                            <MenuItem value="REJECTED">Rejected</MenuItem>
                            <MenuItem value="VERIFIED">Email Verified</MenuItem>
                            <MenuItem value="UNVERIFIED">Email Not Verified</MenuItem>
                        </Select>
                    </FormControl>
                    <div className='col-span-2'>
                        <Search onSearchHandler={(value) => { setCode(value) }} placeholder="EFF-XXX" />
                    </div>
                </div>
                <div className='flex my-2 justify-end'><Button onClick={() => setOpenReport(true)}>Generate Report</Button></div>
                {count > 0 && <div className='flex justify-center py-2'>
                    <Pagination count={Math.ceil(count / limit)} color="primary" />
                </div>}
                <div className='my-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2'>
                    {users.map(user => {
                        return <ApprovalItem user={user} />
                    })}
                </div>
            </Container>
        </div>
    )
}

export default ApprovalsPage