import fetchSingleUser from "./fetch-single-user";
import fetchUsers from "./fetch-users";
import fetchAllUsers from "./fetchAllUsers";
import registerUser from "./register-user";
export default {
  registerUser: registerUser,
  fetchUsers: fetchUsers,
  fetchSingleUser: fetchSingleUser,
  fetchAllUsers: fetchAllUsers
};
