import { Roles } from "./constants";

interface IRoute {
  name: string;
  path: string;
  roles: Roles[];
}
const routes: IRoute[] = [
  {
    name: "Home",
    path: "/",
    roles: [],
  },
  //   {
  //     name: "Registration",
  //     path: "/registration",
  //     roles: [],
  //   },
  //   {
  //     name: "Tracking",
  //     path: "/tracking",
  //     roles: [],
  //   },

  {
    name: "Check Status",
    path: "/permits",
    roles: [],
  },
  {
    name: "Approvals",
    path: "/approvals",
    roles: [Roles.ADMIN],
  },
  {
    name: "Companies",
    path: "/companies",
    roles: [Roles.ADMIN],
  },
  {
    name: "Profile",
    path: "/profile",
    roles: [Roles.ADMIN],
  },
];

export default routes;
