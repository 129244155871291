import React from 'react'
import { useNavigate } from 'react-router-dom';

interface IProps {
    name: string;
    path: string;
    isActive: boolean;
}
const NavItem = (props: IProps) => {
    const navigate = useNavigate()
    return (
        <div onClick={() => navigate(props.path)} className={`flex hover:cursor-pointer flex-col justify-center  px-2 capitalize border-b-4 ${props.isActive ? "border-b-white" : "border-b-gray-800 "}`}>{props.name}</div>
    )
}

export default NavItem