import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAxiosInstance } from "../../helper";
import { AppDispatch, RootState } from "../../store";
import { logout } from "../auth/login";
import { IFetchUsersResponseDto, IFetchUsersState } from "./type";

export const fetchAllUsersHandler = createAsyncThunk<
  IFetchUsersResponseDto,
  void,
  { state: RootState; dispatch: AppDispatch }
>("fetch_all_users", async (_, { getState, dispatch }) => {
  try {
    const { login } = getState();

    const firstResponse = await getAxiosInstance(login.data?.token).get(
      `/users?page=0&limit=1`
    );
    const firstData = firstResponse.data as IFetchUsersResponseDto;
    const secondResponse = await getAxiosInstance(login.data?.token).get(
      `/users?page=0&limit=${firstData.count}`
    );
    return secondResponse.data as IFetchUsersResponseDto;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.data) {
      if (e.response.status === 401) {
        dispatch(logout());
      }
      let { message } = e.response.data as { message: string };
      throw new Error(message);
    } else {
      throw e;
    }
  }
});

const initialState: IFetchUsersState = {
  data: {
    users: [],
    count: 0,
  },
  isLoading: false,
};
const fetchAllUsersSlice = createSlice({
  name: "fetch_all_users",
  initialState,
  reducers: {
    resetFetchAllUsers(state) {
      state.data.users = [];
      state.data.count = 0;
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAllUsersHandler.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchAllUsersHandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;
      state.data = action.payload;
    });
    builder.addCase(fetchAllUsersHandler.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetFetchAllUsers } = fetchAllUsersSlice.actions;
export default fetchAllUsersSlice.reducer;
