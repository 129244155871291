import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/redux-hooks";
import jwt_decode from "jwt-decode";

interface IProps {
    children: React.ReactNode;
}
const PrivateRoute = (props: IProps) => {
    const { data } = useAppSelector((state) => state.login);
    if (!data) {
        return <Navigate to="/login" />
    }
    const { role } = jwt_decode(data.token) as { role: string }
    if (role !== "ADMIN") return <Navigate to="/login" />;
    return <>{props.children}</>;
};

export default PrivateRoute;
