import { Container, FormControlLabel, Stack, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import NavBar from '../components/NavBar'
import useViewport from '../hooks/useViewport'

import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { useNavigate } from 'react-router-dom'
import Footer from '../components/Footer'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import { baseURL, imgURL } from '../helper'
import { getProfileHandler } from '../slices/profile/getProfile'

const enum Language {
    AMHARIC,
    ENGLISH
}
const HomePage = () => {
    const dispatch = useAppDispatch()
    const { height } = useViewport()
    const [language, setLanguage] = useState(Language.AMHARIC)
    const navigate = useNavigate()
    const changeLanguageHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLanguage(language === Language.AMHARIC ? Language.ENGLISH : Language.AMHARIC)
    }

    const { data: profile, isLoading: isProfileLoading, error: profileError } = useAppSelector(state => state.getProfile)
    const [ isImageLoaded, setImageLoaded] = useState(false)

    useEffect(()=>{
        dispatch(getProfileHandler())
    }, [])
    return (
        <div className='overflow-hidden'>
            <NavBar routeName='Home' />
            <div className='relative'>
                <div style={{ height: height * 0.8, overflow: 'hidden' }} className="relative">
                    <img src={`${imgURL}/${profile?.image}`} onLoad={()=> setImageLoaded(true)} className={`h-full w-full object-cover ${!isImageLoaded && "hidden"}`} />
                    <img src="/placeholder.png" className={`w-full object-cover ${isImageLoaded && "hidden"}`}/>
                    <div className='absolute bg-black bg-opacity-50  flex items-center justify-center top-0 bottom-0 left-0 right-0 text-3xl text-white'>
                        <p className='w-1/2 text-center capitalize'> Welcome to the Ethiopia Football Federation  { profile?.assemblyNumber+ "th " + profile?.assemblyName} Media accreditation Website !</p>
                    </div>
                </div>
                <Container>
                    <div className='grid md:grid-cols-2 py-10 gap-x-5 gap-y-5 relative md:-top-20 z-10'>
                        <div className=' bg-gray-800 text-white rounded-lg flex flex-col items-center p-3'>
                            <SportsSoccerIcon fontSize='large' />
                            <p className='text-2xl uppercase hover:underline hover:cursor-pointer' onClick={() => navigate("/registration")}>register</p>
                            <p className='px-10'>Are you a media personnel and planning to attend Ethiopian Football Federation {profile?.assemblyNumber + "th " +profile?.assemblyName} ? Register and let us get notified. </p>
                        </div>
                        <div className=' bg-gray-800 text-white rounded-lg flex flex-col items-center p-3'>
                            <SportsSoccerIcon fontSize='large' />
                            <p className='text-2xl uppercase hover:underline hover:cursor-pointer' onClick={() => navigate("/permits")}>Check Status</p>
                            <p className='px-10'>What is the status of your acceredation request ? Provide your tracking number and check it now.</p>
                        </div>
                    </div>
                </Container>
            </div>
            <div className='px-2'>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-10'>
                    <div className='overflow-clip rounded-md'>
                        <img src={`${imgURL}/${profile?.image}`} onLoad={()=> setImageLoaded(true)} className={`w-full object-cover ${!isImageLoaded && "hidden"}`} />
                        <img src="/placeholder.png" className={`w-full object-cover ${isImageLoaded && "hidden"}`}/>
                    </div>
                    <div>
                        <div className='flex justify-end'>
                            <Stack direction="row" alignItems="center">
                                <Switch checked={language === Language.ENGLISH} onChange={changeLanguageHandler} />
                                <p>{language === Language.AMHARIC ? "አማርኛ" : "English"}</p>
                            </Stack>
                        </div>
                        {language === Language.ENGLISH && <div className='flex flex-col items-center justify-center'>
                            <p className='text-3xl text-center capitalize'>instructions</p>
                            <ol className='list-decimal px-10'>
                                <li>Go to registration page</li>
                                <li>Enter all relevant information</li>
                                <li>You will receive a confirmation message on your registered email by clicking on verification.</li>
                                <li>After the confirmation message, open the email you receive and you will receive a serial number (for example EFF-xxx).</li>
                                <li>Go back to the main page and press the check status link and enter your account number to find out if your registration was accepted and print out your badge.</li>
                                <li>When you are on the day of the General Assembly, the bar code on the badge will be verified and you will be logged in.</li>
                            </ol>
                        </div>}
                        {
                            language === Language.AMHARIC && <div className='flex flex-col items-center justify-center'>
                                <p className='text-3xl text-center capitalize'>መመሪያዎች</p>
                                <ol className='list-decimal px-10'>
                                    <li>Register የሚለውን ቁልፍ ይጫኑ።</li>
                                    <li>በመቀጠል በቅፁ ላይ ያሉትን አስፈላጊ መረጃዎች ያስገቡ።</li>
                                    <li>በተመዘገቡበት ኢሜል የማረጋጋጫ መልእክት ይደርሶታል እሱን በመጫን መድረሱን ያረጋግጡ።</li>
                                    <li>ከማረጋጋጨ መልዕክቱ በመቀጠል የሚደርሰዎት ኢሚል በመክፈት የመከታታያ መለያ ቁጥር ያገኛሉ (ምሳሌ፡ EFF-xxx)</li>
                                    <li>ወደ ዋናው ገጽ ተመለስ እና ሁኔታ ማገናኛን ተጫን እና ምዝገባህ ተቀባይነት ማግኘቱን ለማወቅ መለያ ቁጥርህን አስገባ እና ባጅህን አትም።</li>
                                    <li>የሚያገኙትን ባጅ ፕሪንት ወይንም ስልክዎት ላይ በማስቀመጥ (screenshot) በጥቅላላ ጉባኤ ቀን ቦታው ላይ ሲገኙ ባጁ ላይ ያለው ባር ኮድ ተረጋግጦ ወደ ውስጥ የሚገቡ ይሆናል።</li>
                                </ol>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HomePage