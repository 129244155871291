import { Table, TableCell, TableRow } from '@mui/material'
import React, { useEffect } from 'react'
import { baseURL, imgURL } from '../../helper'
import header from "../../images/header.png"
import press from "../../images/press.png"
import { IUser } from '../../slices/users/type'
import QRCode from 'qrcode'
import logo from "../../images/logo.png"
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import { getProfileHandler } from '../../slices/profile/getProfile'
interface IProps {
    user: IUser
}
const PermitId = (props: IProps) => {
    const { user } = props
    const dispatch = useAppDispatch()
    const { data: profile } = useAppSelector(state => state.getProfile)
    const fullName = `${user.firstName} ${user.middleName} ${user.lastName}`

    useEffect(() => {
        const canvas = document.getElementById("qrCode");
        if (canvas && user.qrToken) {
            QRCode.toCanvas(canvas, user.qrToken, (err) => {
                console.log(err)
            })
        }

    }, [user.qrToken])


    useEffect(() => {
        dispatch(getProfileHandler())
    }, [])
    return (
        <div className='pb-10 py-3'>
            <div className='bg-gray-50 border rounded-md overflow-clip'>
                <div className='flex my-2'>
                    <img src={logo} className="w-28 h-28 ml-10" />
                    <div className='grow'>
                        <p className='text-center text-xl'>{profile?.assemblyNumber}<sup>th</sup></p>
                        <p className='uppercase text-center font-bold text-xl'>Ethiopian Football Federation</p>
                        <p className='uppercase text-center font-bold text-3xl'>{profile?.assemblyName}</p>
                    </div>
                </div>
                <img src={press} className="w-full" />
                <div className='my-5 flex flex-col items-center'>
                    <div className='flex justify-center row-span-5 relative'>
                        <img src={`${imgURL}/${user.image}`} className=" rounded-full object-cover h-52 w-52" />
                        <img className='h-14 w-14 rounded-full absolute bottom-0 left-0' src={`${baseURL}/${user.company.logo}`} />
                    </div>
                    <div className='uppercase text-center font-bold text-2xl mt-5'>{fullName}</div>
                    <div className='uppercase text-center  text-xl'>{user.position}</div>

                    <div className='mt-5 w-full flex items-center justify-center '>
                        <canvas id="qrCode"></canvas>
                    </div>
                </div>
                <div className='flex justify-around text-sm capitalize mt-10 mb-5 items-center'>
                    <span>{user.company.name}</span>
                    <span>{user.company.email}</span>
                    <span>{user.company.phoneNumber}</span>
                </div>
            </div>

        </div>
    )
}

export default PermitId