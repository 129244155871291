import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAxiosInstance } from "../../helper";
import { AppDispatch, RootState } from "../../store";
import { logout } from "../auth/login";
import { IFetchCompaniesResponseDto, IFetchCompaniesState } from "./type";

interface IFetchCompaniesThunkData {
  page?: number;
  limit?: number;
}
export const fetchCompaniesHandler = createAsyncThunk<
  IFetchCompaniesResponseDto,
  IFetchCompaniesThunkData,
  { state: RootState; dispatch: AppDispatch }
>("fetch_companies", async (data, { getState, dispatch }) => {
  try {
    let query = [];
    if (data.limit) {
      query.push(`limit=${data.limit}`);
    }
    if (data.page) {
      query.push(`page=${data.page}`)
    }
    const { login } = getState();

    const response = await getAxiosInstance(login.data?.token).get(
      `/companies?${query.join("&")}`
    );
    return response.data as IFetchCompaniesResponseDto;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.data) {
      if (e.response.status === 401) {
        dispatch(logout());
      }
      let { message } = e.response.data as { message: string };
      throw new Error(message);
    } else {
      throw e;
    }
  }
});



const initialState: IFetchCompaniesState = {
  data: {
    companies: [],
    count: 0,
  },
  isLoading: false,
};
const fetchCompaniesSlice = createSlice({
  name: "fetch_companies",
  initialState,
  reducers: {
    resetFetchCompanies(state) {
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCompaniesHandler.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchCompaniesHandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;
      state.data = action.payload;
    });
    builder.addCase(fetchCompaniesHandler.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetFetchCompanies } = fetchCompaniesSlice.actions;
export default fetchCompaniesSlice.reducer;
