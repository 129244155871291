import { Alert, Backdrop, CircularProgress, Container, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import NavBar from '../components/NavBar'
import noImage from "../images/no-image.jpg"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelButton from '../components/CancelButton';
import SubmitButton from '../components/SubmitButton';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { changeProfileHandler } from '../slices/profile/changeProfile';
import { getProfileHandler } from '../slices/profile/getProfile';
import { baseURL, imgURL } from '../helper';

interface IForikValues {
    assemblyName?: string;
    assemblyNumber?: number;
}

const ChangeProfileValidationSchema = Yup.object().shape({
    assemblyName: Yup.string(),
    assemblyNumber: Yup.number()
});

const ProfilePage = () => {
    const { isSuccessful: isChangeProfileSuccessful, isLoading: isChangeProfileLoading, error: changeProfileError } = useAppSelector(state => state.changeProfile)
    const { data: profile, isLoading: isGetProfileLoading, error: getProfileError } = useAppSelector(state => state.getProfile)
    const [image, setImage] = useState<File | undefined>();
    const imageRef = useRef<HTMLInputElement | null>(null);
    const [preview, setPreview] = useState(() => noImage);
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (image) {
            const objectUrl = URL.createObjectURL(image);
            setPreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, [image]);

    const formik = useFormik<IForikValues>({
        initialValues: {},
        validationSchema: ChangeProfileValidationSchema,
        onSubmit(values, formikHelpers) {
            console.log(values)
            dispatch(changeProfileHandler({
                assemblyNumber: values.assemblyNumber,
                title: values.assemblyName,
                id: profile ? profile.id : undefined,
                pic: image
            }))
        },
    })

    useEffect(() => {
        if (profile) {
            formik.setValues({
                assemblyName: profile.assemblyName === null ? undefined : profile.assemblyName ,
                assemblyNumber: profile.assemblyNumber == null ? undefined : profile.assemblyNumber
            })
            if (profile.image){
                setPreview(`${imgURL}/${profile.image}`)
            }
        }
    }, [profile])

    useEffect(() => {
        dispatch(getProfileHandler())
    }, [])


    return (
        <div>
            <NavBar routeName='Profile' />
            <Container className='mt-10'>
                <div className="my-2">
                    {getProfileError && <Alert severity="error">{getProfileError}</Alert>}
                    {isChangeProfileSuccessful && <Alert>Profile Successfully Updated</Alert>}
                    {changeProfileError && <Alert severity="error">{changeProfileError}</Alert>}
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isChangeProfileLoading || isGetProfileLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <form onSubmit={formik.handleSubmit}>
                    <div className='flex flex-col items-center'>
                        <input
                            accept='.png, .jpg, .jpeg'
                            onChange={(e) => {
                                if (e.target.files !== null) {
                                    setImage(e.target.files[0]);
                                }
                                e.target.value = ""
                            }}
                            ref={imageRef}
                            type="file"
                            style={{ display: "none" }}
                        />
                        <img src={preview} className="h-40 w-48 object-cover" />
                        <button onClick={() => imageRef.current?.click()} type="button" className='w-48 flex px-3 py-1 space-x-2 items-center justify-center '>
                            <div><CloudUploadIcon /></div>
                            <div>Upload Image</div>
                        </button>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 mt-10 gap-2'>
                        <div>
                            <p>Assembly Title</p>
                            <TextField placeholder="Assembly Title" fullWidth
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.assemblyName}
                                name="assemblyName" />
                            <p className="text-xs text-red-500 pl-2">{formik.touched.assemblyName && formik.errors.assemblyName}</p>
                        </div>
                        <div>
                            <p>Assembly Number</p>
                            <TextField placeholder="Assembly Number" fullWidth
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.assemblyNumber}
                                name="assemblyNumber"
                            />
                            <p className="text-xs text-red-500 pl-2">{formik.touched.assemblyNumber && formik.errors.assemblyNumber}</p>
                        </div>
                    </div>
                    <div className='space-x-2 flex justify-end mt-10'>
                        <CancelButton onClickHandler={() => { }} text="reset" />
                        <SubmitButton text='submit' />
                    </div>
                </form>
            </Container>
        </div>
    )
}

export default ProfilePage