import { CircularProgress, Container, Dialog, DialogContent, IconButton, Pagination, Table, TableCell, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import NavBar from '../components/NavBar'
import SearchIcon from '@mui/icons-material/Search';
import Search from '../components/Search';
import PermitId from '../components/permits/PermitId';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Footer from '../components/Footer';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { fetchUsersHandler } from '../slices/users/fetch-users';
import { IUser } from '../slices/users/type';
import { baseURL, imgURL } from '../helper';
import useViewport from '../hooks/useViewport';
import { statusButton } from '../components/approval/ApprovalItem';
import { fetchUserByCodeHandler, resetFetchSingleUser } from '../slices/users/fetch-single-user';

const PermitsPage = () => {
    const [openIdContent, setOpenIdContent] = useState(false)
    const dispatch = useAppDispatch()
    const { user, error: fetchSingleUserError, isLoading: isSearchUserLoading } = useAppSelector(state => state.fetchSingleUser)
    const { height } = useViewport()

    useEffect(() => {
        return () => {
            dispatch(resetFetchSingleUser())
        }
    }, [])
    return (
        <div>
            <NavBar routeName='Check Status' />
            <Container>
                {user && <Dialog open={openIdContent} fullWidth>
                    <DialogContent>
                        <div className='flex justify-end print:hidden '>
                            <IconButton onClick={() => setOpenIdContent(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='mt-5 flex justify-end print:hidden'>
                            <button onClick={() => window.print()} className='flex space-x-2 capitalize outline-none'>
                                <PrintIcon />
                                <div>Print</div>
                            </button>
                        </div>
                        <PermitId user={user} />
                    </DialogContent>
                </Dialog>}
                <div className='mt-10 print:hidden'>
                    <Search onSearchHandler={(value) => { dispatch(fetchUserByCodeHandler(value.trim())) }} placeholder="EFF-XXX" />
                </div>
                <div style={{ minHeight: `${(height * 0.6)}px` }}>
                    {isSearchUserLoading && <div className='mt-20 flex items-center justify-center'>
                        <CircularProgress />
                    </div>}
                    {user && <div >
                        <div className='flex justify-center my-5'>
                            <div className='space-y-2'>
                                <img className='w-52 rounded-md' src={`${imgURL}/${user?.image}`} />
                                <div className='flex justify-center'>
                                    {statusButton(user.status)}
                                </div>
                            </div>

                        </div>
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-y-5 gap-x-2  mt-10 print:hidden'>
                            <div>
                                <h1 className='text-center font-bold'>Personal Information</h1>
                                <Table className='mt-5'>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>{`${user?.firstName} ${user?.middleName} ${user?.lastName}`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Date of Birth</TableCell>
                                        <TableCell>{user?.birthDate}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Phone Number</TableCell>
                                        <TableCell>{user?.phoneNumber || "--"}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Email</TableCell>
                                        <TableCell>{user?.email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>City</TableCell>
                                        <TableCell className='capitalize'>{user?.city || "--"}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='capitalize'>Sub-City</TableCell>
                                        <TableCell>{user?.subCity || "--"}</TableCell>
                                    </TableRow>
                                </Table>
                            </div>
                            <div>
                                <h1 className='text-center font-bold'>Work Information</h1>
                                <Table className='mt-5'>
                                    <TableRow>
                                        <TableCell>Organization Name</TableCell>
                                        <TableCell className='capitalize'>{user?.company.name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Address</TableCell>
                                        <TableCell className='capitalize'>{user?.company.address}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Position</TableCell>
                                        <TableCell className='capitalize'>{user?.position}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Email</TableCell>
                                        <TableCell>{user?.company.email || "--"}</TableCell>
                                    </TableRow>
                                </Table>
                                {user.status === "APPROVED" && <button onClick={() => setOpenIdContent(true)} className='w-full my-3 p-2 text-center bg-gray-800 text-white rounded-md flex justify-center space-x-2'>
                                    <AutorenewIcon />
                                    <p>Generate Identification Card</p>
                                </button>}
                            </div>
                        </div>
                    </div>}
                </div>
            </Container>
            <Footer />
        </div>
    )
}

export default PermitsPage